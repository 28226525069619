<template>
  <div>
    <b-row>
      <b-col>
        <div id="component-breadcrumbs">
          <b-breadcrumb
            class="breadcrumb-slash"
            :items="breadcrumbItems"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2>Impact Rating Editor</h2>
      </b-col>
    </b-row>
    <hr>
    <div class="row mb-1">
      <!-- add button -->
      <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
        <b-button
          block
          variant="primary"
          style="
justify-content: space-around;
display: flex;
border-radius: 0;
width: max-content;
"
          @click="$router.push('/admin/utility/add-impact-rating')"
        >
          <span>+ Add Impact Rating</span>
        </b-button>
      </div>
    </div>
    <b-row>
      <!-- Table -->
      <b-col class="border-top-dark min-height-10 rounded-sm">
        <b-row
          class="bg-light border-left-dark border-right-dark height-50 d-flex align-items-center"
          :class="{'border-bottom-dark': !impactRatings.length}"
        >
          <b-col md="2">
            Impact Rate
          </b-col>
          <b-col>Impact Reason</b-col>
          <b-col md="1">
            Actions
          </b-col>
        </b-row>
        <b-row
          v-for="(item, index) of impactRatings"
          :key="index"
          class="border-left-dark border-right-dark d-flex align-items-center"
          :class="{'border-bottom-dark': index + 1 === impactRatings.length}"
        >
          <b-col>
            <b-row class="pt-1 border-top">
              <b-col md="2">
                <span class="text-nowrap">{{ item.ImpactRate }}</span>
              </b-col>
              <b-col>
                <span>{{ item.ImpactReason }}</span>
              </b-col>
              <b-col md="1">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item>
                      <span @click="editImpactRating(item.ImpactRatingID)">Edit</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BDropdown, BDropdownItem, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BBreadcrumb,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      impactRatings: [],
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Admin',
        },
        {
          text: 'Utility',
          href: '/admin/utility'
        },
        {
          text: "Impact Rating Editor",
        },
      ]
    },
  },
  created() {
    this.loadImpactRating();
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    loadImpactRating() {
      apiService
          .get("impact-rating/list")
          .then(res => {
            this.impactRatings = res.data;
          })
    },
    editImpactRating(val) {
      this.$router.push('/admin/utility/edit-impact-rating/' + val)
    },
  }
}
</script>

<style scoped>

</style>